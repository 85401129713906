.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-facebook {
  background: #3B5998;
  color: #fff;

}

.my-facebook-button-class {
  align-items: center;
  background-color: #1778F2;
  border: none;
  border-radius: 0.313rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: 'SamsungOne';
  font-size: 1rem;
  font-weight: 600;
  justify-content: space-evenly;
  padding: 1rem;
  width: 10.75rem;
}

.my-facebook-button-class:hover {
  background-color: #73B1FF;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#034EA2;
}

/* Handle on active */
::-webkit-scrollbar-thumb:active {
  background:#0c3665;
}
